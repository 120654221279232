.Header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  padding-right: 10px;
  padding-left: 10px;

  border-bottom: 1px solid #BCBCBC;
}