@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;700;800;900&display=swap);
.Avatar_Container__HCx_Q{width:100%;max-width:200px}.Avatar_Container__HCx_Q .Avatar_item__2drqS{width:100%;height:36px;cursor:pointer;padding:12px 36px;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.Avatar_Container__HCx_Q .Avatar_item__2drqS:hover{background-color:#F2F2F2}.Avatar_Container__HCx_Q .Avatar_item__2drqS span{font-weight:600;font-size:16px;line-height:20px}.Avatar_Container__HCx_Q .Avatar_item__2drqS .Avatar_profile__8pkxg a{color:inherit}

.Menu_Menu__1fk5z{display:-webkit-flex;display:flex;width:50%}.Menu_Menu__1fk5z .Menu_item__22eVJ{max-width:20%;padding-right:49px;font-family:var(--font-family);font-weight:600;font-size:16px;line-height:20px;letter-spacing:0.2px;color:#000;-webkit-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer}

.Header_Header__214zg{width:100%;height:50px;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between;padding-right:10px;padding-left:10px;border-bottom:1px solid #BCBCBC}

.Spin_spin-container__1K3sM{margin:20px 0;margin-bottom:20px;padding:30px 50px;text-align:center;border-radius:4px}

:root{--font-family: "Mulish", sans-serif}

